import { AppRouter } from "./router/AppRouter";
import { AppTheme } from "./theme";

function App() {
  return (
    <AppTheme>
      <AppRouter/>
    </AppTheme>
  );
}

export default App;
