import React from 'react'
import { Grid, Box } from '@mui/material';

export const FranchiseLayout = ({ children }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding={{ xs: 0, sm: 6 }}
        >
            <Grid
                item
                xs={12} sm={12} md={8} lg={6} xl={4}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: { xs: 0, sm: '10px' },
                        boxShadow: '0px 1901.41px 760.792px rgba(0, 0, 0, 0.01), 0px 1069.68px 641.811px rgba(0, 0, 0, 0.05), 0px 474.78px 474.78px rgba(0, 0, 0, 0.09), 0px 118.981px 261.987px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);',
                    }}
                >
                    {children}
                </Box>
            </Grid>
        </Grid>
    )
}
