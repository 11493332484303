import React from 'react'
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/lazy";

// import required modules
import { Navigation, Pagination, EffectFade, Lazy } from "swiper";
import { distanceBetween } from '../../helpers/distance';
import { useCoordinates } from '../../hooks/useCoordinates';

export const Card = ({displayName = '', categories = [], address = '', location, score = 3, price_level = 3, facadeImagesUrl = [], happyIntervals = [], links = [], distance}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const coordinates = useCoordinates();

    const happyHourMsg = React.useMemo(() => {
        if(happyIntervals.length > 0){
            //structure is {days: [1,4,5], duration: 120, open: 1200, enable: true}
            const today = new Date();
            const day = today.getDay();
            const hour = today.getHours();
            const minutes = today.getMinutes();
            const time = hour * 60 + minutes;
            const happyInterval = happyIntervals.find((interval) => interval.days.includes(day) && interval.open <= time && interval.open + interval.duration >= time);
            if(happyInterval){
                return `Happy hour desde las ${Math.floor(happyInterval.open / 60)}:${happyInterval.open % 60 === 0 ? '00' : happyInterval.open % 60}hs hasta las ${Math.floor((happyInterval.open + happyInterval.duration) / 60)}:${(happyInterval.open + happyInterval.duration) % 60 === 0 ? '00' : (happyInterval.open + happyInterval.duration) % 60}hs`;
            }
            return '';
        }
        return '';
    }, [happyIntervals]);

    const distanceInFormat = React.useMemo(() => {
        if(distance){
            if(distance < 1000){
                return `${distance}m`;
            }
            return `${(distance / 1000).toFixed(1)}km`;
        }
    }, [distance]);

    const handleClick = () => {
        if(links){
            window.open(links.profile, '_blank');
        }
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
        >
            <Box>
                <Swiper
                    modules={[Navigation, Pagination, EffectFade, Lazy]}
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={isMobile ? false : true}
                    effect={'fade'}
                    lazy={true}
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                        //reduce pagination size
                        "--swiper-navigation-size": "2rem",
                    }}
                    onClick={handleClick}
                >
                    {/* <SwiperSlide>
                        <img
                            src="https://picsum.photos/200"
                            alt="Franchise"
                            style={{
                                objectFit: 'cover',
                                height: isMobile ? '8rem' : '13rem',
                                width: '100%',
                                borderRadius: '10px',
                            }}
                            className="swiper-lazy"
                        />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </SwiperSlide> */}
                    {
                        facadeImagesUrl.map((url) => (
                            <SwiperSlide key={url}>
                                <img
                                    src={url}
                                    alt="Franchise"
                                    style={{
                                        objectFit: 'cover',
                                        height: isMobile ? '12rem' : '17rem',
                                        width: '100%',
                                        borderRadius: '10px',
                                    }}
                                    className="swiper-lazy"
                                />
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Box>
            <Box 
                display={'flex'} 
                flexDirection='column'
                sx={{cursor: 'pointer'}}
                onClick={handleClick}
            >
                <Grid container marginBottom={1}>
                    <Grid item xs={10}>
                        <Typography variant={'h2'}>{displayName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display={'flex'} alignItems='center' justifyContent={'flex-end'}>
                            <Typography variant={'h2'}>{score}</Typography>
                            <StarIcon color={'primary'} sx={{ fontSize: '25px' }} />
                        </Box>
                    </Grid>
                </Grid>
                <Box display={'flex'} gap={1}>
                    <Typography variant={'subtitle2'} gutterBottom>{categories.length > 0 ? categories[0] : 'Restaurante'}</Typography>
                    <Typography variant={'subtitle2'} gutterBottom>•</Typography>
                    <Typography variant={'body2'} gutterBottom>
                        {
                            // as many $ as price_level
                            Array.from({length: price_level}, (_, i) => '$').join('')
                        }
                    </Typography>
                    <Typography variant={'subtitle2'} gutterBottom>•</Typography>
                    <Typography variant={'body2'} gutterBottom>{distanceInFormat}</Typography>
                </Box>
                <Box display={'flex'}>
                    <LocationOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                    <Typography variant={'h5'} gutterBottom>{address}</Typography>
                </Box>
                <Typography variant={'h6'} sx={{ fontStyle: 'italic' }}>{happyHourMsg}</Typography>
            </Box>
        </Box >
    )
}
