import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import FranchiseApp from './FranchiseApp'
import './styles.css'

ReactDOM.createRoot(document.getElementById('root')).render(
     <HelmetProvider>
      <BrowserRouter>
        <FranchiseApp />
      </BrowserRouter>
     </HelmetProvider>
)
