import { Navigate, Route, Routes } from 'react-router-dom'
import { FranchisePage } from '../franchise/pages/FranchisePage'

export const AppRouter = () => {

    return (
        <Routes>
            <Route path='/' element={<FranchisePage />} />
            <Route path='/*' element={<Navigate to='/' />} />
        </Routes>
    )
}
