import React, { useEffect } from 'react'
import { useTheme, useMediaQuery, Box, Divider, Typography } from '@mui/material';
import { Card, HeaderFranchise } from '../components';

export const FranchiseView = ({name = '', logo = '', facade = '', partners = [], category = ''}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [paginatedPartners, setPaginatedPartners] = React.useState([]);
    useEffect(() => {
        if (partners) {
            setPaginatedPartners(partners.slice(0, 6));
        }
    }, [partners]);

    const checkpoint = React.useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setPaginatedPartners(partners.slice(0, paginatedPartners.length + 6));
            }
        }, { threshold: 1 });
        if (checkpoint.current) {
            observer.observe(checkpoint.current);
        }
        return () => {
            if (checkpoint.current) {
                observer.unobserve(checkpoint.current);
            }
        };
    }, [checkpoint, paginatedPartners, partners]);
    return (
        <>
            <img
                src={facade}
                alt={name}
                style={{
                    objectFit: 'cover',
                    height: isMobile ? '12rem' : '15rem',
                    width: '100%',
                    borderRadius: isMobile ? '0px' : '10px 10px 0px 0px',
                }}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                padding={isMobile ? '1rem 1.5rem' : '1rem 2rem'}
                gap={3}
                position={'relative'}
                top={isMobile ? '-3rem' : '-4rem'}
            >
                <HeaderFranchise 
                    name={name}
                    logo={logo}
                    category={category}
                />
                <Divider />
                <Typography variant={'body1'}>{partners.length} restaurantes</Typography>

                {
                    paginatedPartners.map((partner) => (
                        <Card
                            key={partner.id}
                            {...partner}
                        />
                    ))
                }
                <div ref={checkpoint}/>

            </Box>
        </>

    )
}
