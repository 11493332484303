import { createTheme } from "@mui/material";

export const wokiTheme = createTheme({
    palette: {
        primary: {
            main: '#041C32',
            light: '#041C32',
            dark: '#041C32',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: [
            "Poppins",
            "sans-serif",
        ].join(","),
        h1: {
            fontSize: "2rem",
            fontWeight: 700,
            color: "#041C32",
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#041C32",
        },
        body1: {
            fontSize: "1.3rem",
            fontWeight: 500,
            color: "#041C32",
        },
        h2: {
            fontSize: "1.4rem",
            fontWeight: 600,
            color: "#041C32",
        },
        subtitle2: {
            fontSize: "1rem",
            fontWeight: 700,
            color: "#041C32",
        },
        body2: {
            fontSize: "1rem",
            fontWeight: 400,
            color: "#041C32",
        },
        h5: {
            fontSize: "0.9rem",
            fontWeight: 400,
            color: "#041C32",
        },
        h6: {
            fontSize: "0.9rem",
            fontWeight: 300,
            color: "#041C32",
        },
    }, 
});
