import { CssBaseline, ThemeProvider } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";
import {wokiTheme} from "./"

export const AppTheme = ({children}) => {
    let theme = wokiTheme;
    theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        {children}
    </ThemeProvider>
  )
}
