import React from 'react'
import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';


export const HeaderFranchise = ({name = '', logo = '', category = ''}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            gap={3}
        >
            <img
                src={logo}
                alt={name}
                style={{
                    objectFit: 'cover',
                    height: isMobile ? '8rem' : '10rem',
                    width: isMobile ? '8rem' : '10rem',
                    borderRadius: '10px',
                }}
            />
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignSelf={'flex-end'}
                marginBottom={isMobile ? '0rem' : '1rem'}
                gap={1}
            >
                <Typography variant={'h1'}>{name}</Typography>
                <Typography variant={'subtitle1'}>{category}</Typography>
            </Box>
        </Box>
    )
}
