import React from 'react'
import { useGeolocated } from 'react-geolocated'
import { Helmet } from 'react-helmet-async'
import { sortListByDistance } from '../../helpers/distance'
import { useCoordinates } from '../../hooks/useCoordinates'
import { useFranchise } from '../../hooks/useFranchise'
import { FranchiseLayout } from '../layout/FranchiseLayout'
import { FranchiseView, LoadingFranchise } from '../views'

export const FranchisePage = () => {

  const { franchise, loading } = useFranchise();
  const { coords: coordinates, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  const [partnersInOrder, setPartnersInOrder] = React.useState([]);
  React.useEffect(() => {
    if (franchise && franchise.partners) {
      if (isGeolocationAvailable && isGeolocationEnabled) {
        setPartnersInOrder(sortListByDistance(franchise.partners, coordinates?.latitude, coordinates?.longitude));
      } else {
        setPartnersInOrder(franchise.partners);
      }
    }
  }, [coordinates, franchise]);

  return (
    <>
      <Helmet>
        <title>{loading ? 'Cargando...' : `${franchise?.name} - Woki Franquicias`}</title>
        <link rel="canonical" href={window.location.href} />
        <link rel="icon" href={franchise?.logo} />
        <meta name="description" content={franchise?.name} />
        <meta name="keywords" content={franchise?.name} />
        <meta name="author" content="Woki" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="nositelinkssearchbox" />
        <meta name="google" content="notranslate" />
        <meta name="google" content="nocache" />
      </Helmet>
      <FranchiseLayout>
        {
          (
            loading || (
              isGeolocationAvailable && isGeolocationEnabled && !coordinates))
            ? <LoadingFranchise />
            : <FranchiseView {...franchise} partners={partnersInOrder} />
        }
      </FranchiseLayout>
    </>
  )
}
