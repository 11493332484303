import React from 'react'
import { useTheme, useMediaQuery, Box, Divider, Skeleton } from '@mui/material';

export const LoadingFranchise = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={isMobile ? '12rem' : '17rem'}
        sx={{
          borderRadius: isMobile ? '0px' : '10px 10px 0px 0px',
        }}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={isMobile ? '1rem 1.5rem' : '1rem 2rem'}
        gap={3}
        position={'relative'}
        top={isMobile ? '-3rem' : '-4rem'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={3}
        >
          <Skeleton
            variant="rectangular"
            width={isMobile ? '8rem' : '10rem'}
            height={isMobile ? '8rem' : '10rem'}
            sx={{borderRadius: '10px'}}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignSelf={'flex-end'}
            gap={1}
          >
            <Skeleton
              variant="text"
              width={isMobile ? '10rem' : '15rem'}
              height={isMobile ? '2rem' : '3rem'}
            />
            <Skeleton
              variant="text"
              width={isMobile ? '8rem' : '10rem'}
              height={isMobile ? '1.5rem' : '2rem'}
            />
          </Box>
        </Box>
        <Divider />
        <Skeleton
          variant="text"
          width={isMobile ? '30%' : '20%'}
          height={isMobile ? '1.5rem' : '2rem'}
        />
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height={isMobile ? '12rem' : '17rem'}
              sx={{borderRadius: '10px'}}
            />
          ))}
      </Box>
    </>
  )
}
