import { useEffect, useState } from "react";


export const useCoordinates = () => {
    const [coordinates, setCoordinates] = useState({
        latitude: 0,
        longitude: 0,
        accepted: false,
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accepted: true,
            });
        },
            (error) => {
                setCoordinates({
                    latitude: 0,
                    longitude: 0,
                    accepted: false,
                });
            });
    }, []);

    return coordinates;
}