const deg2rad = deg => deg * (Math.PI / 180);

export const distanceBetween = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return Math.round(d * 1000); // Distance in meters
}

export const sortListByDistance = (list = [], lat = 0, lon = 0) => {
    const listWithDistance = list.map(item => ({
        ...item,
        distance: distanceBetween(lat, lon, item.location.coordinates[1], item.location.coordinates[0]),
    }));
    return listWithDistance.sort((a, b) => a.distance - b.distance);
}